
window.loadSearchBlock1 = function (div_name, json_request)
{

json_request['with_template'] = true;


//{ name: "John", location: "Boston" }
//alert('loadItemBlock1 01 ' + block_template('sss'));
//alert(json_request);
	var url = web.api + '/search';
	$.ajax({
		type: "GET",
		async: false,
		url: url,
		datatype: 'json',
		data: json_request,
		success: function (htmlText) {
//alert('#' + div_name + htmlText);
			$('#' + div_name).html(htmlText);
			//$('.carousel').carousel();


		},
		error: function (e) {

//			alert('error');
//console.log(result);
$.each(e, function( k, v ) {
console.log( "Key: " + k + ", Value: " + v );
});
		}
	});
}

window.loadItemBlock2 = function (name, block_template, json_request)
{

//alert('json_request' + JSON.stringify(json_request));
//{ name: "John", location: "Boston" }
//alert('loadItemBlock1 01 ' + block_template('sss'));


	var url = web.api + '/search';
	$.ajax({
		type: "GET",
		async: false,
		url: url,
		datatype: 'json',
		data: json_request,
		success: function (result) {

			//console.log(result);
//alert(name);

				block_template(name, result);

		},
		error: function (e) {

//alert(e.status + ' ' + e.statusText);
 			$.each(e, function( k, v ) {


	// console.log("Key: " + k + ", Value: " + v );
 });

		}
	});
}
